import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'Login',
            component: () => import('../components/Login.vue')
        },
        {
            path: '/user/list',
            name: 'UserList',
            component: () => import('../components/backend/user/ListUser.vue')
        },
        {
            path: '/changeprofile',
            name: 'ChangeProfile',
            component: () => import('../components/backend/user/ChangeProfile.vue')
        },
        {
            path: '/updateusers/:id',
            name: 'AddUpdateUsers',
            component: () => import('../components/backend/user/AddUpdateUsers.vue')
        },
        {
            path: '/menu/add-edit-menu',
            name: 'AddEditMenu',
            component: () => import('../components/AddEditMenu.vue')
        },
        {
            path: '/roreversal/list',
            name: 'ListROReversalRequest',
            component: () => import('../components/Forms/ROReversalRequest/ListROReversalRequest.vue')
        },
        /* {
            path: '/roreversal/list',
            name: 'ListIframeROReversalRequest',
            // component: ListIframeROReversalRequest,
            component: () => import('../components/Forms/ROReversalRequest/ListIframeROReversalRequest.vue')
        }, */
        {
            path: '/roreversal/add',
            name: 'AddUpdateROReversalRequest',
            component: () => import('../components/Forms/ROReversalRequest/AddUpdateROReversalRequest.vue')
        },
        {
            path: '/roreversal/edit/:id',
            name: 'UpdateROReversalRequest',
            component: () => import('../components/Forms/ROReversalRequest/AddUpdateROReversalRequest.vue')
        },
        {
            path: '/techtime/list',
            name: 'ListTechTime',
            component: () => import('../components/Forms/TechTimeMaster/ListTechTime.vue')
        },
        /* {
            path: '/techtime/list',
            name: 'ListIframeTechTime',
            // component: ListIframeTechTime,
            component: () => import('../components/Forms/TechTimeMaster/ListIframeTechTime.vue')
        }, */
        {
            path: '/techtime/add',
            name: 'AddUpdateTechTime',
            component: () => import('../components/Forms/TechTimeMaster/AddUpdateTechTime.vue')
        },
        {
            path: '/training/request/list',
            name: 'ListTrainingRequest',
            component: () => import('../components/Forms/TrainingRequestMaster/ListTrainingRequest.vue')
        },
        /*{
            path: '/training/request/add',
            name: 'AddUpdateTrainingRequest',
            component: () => import('../components/Forms/TrainingRequestMaster/AddUpdateTrainingRequest.vue')
        },
        {
            path: '/training/request/edit/:id',
            name: 'UpdateTrainingRequest',
            component: () => import('../components/Forms/TrainingRequestMaster/AddUpdateTrainingRequest.vue')
        },*/
        {
            path: '/techtime/spanish/list',
            name: 'ListSpanishTechTime',
            component: () => import('../components/Forms/TechTimeMaster/ListSpanishTechTime.vue')
        },
        {
            path: '/techtime/spanish/add',
            name: 'AddUpdateSpanishTechTime',
            component: () => import('../components/Forms/TechTimeMaster/AddUpdateSpanishTechTime.vue')
        },
        {
            path: '/dayforace/time/list',
            name: 'ListSupportStaff',
            component: () => import('../components/Forms/SupportStaffMaster/ListSupportStaff.vue')
        },
        /* {
            path: '/dayforace/time/list',
            name: 'ListIframeSupportStaff',
            // component: ListIframeSupportStaff,
            component: () => import('../components/Forms/SupportStaffMaster/ListIframeSupportStaff.vue')
        }, */
        {
            path: '/dayforace/time/add',
            name: 'AddUpdateSupportStaff',
            component: () => import('../components/Forms/SupportStaffMaster/AddUpdateSupportStaff.vue')
        },
        {
            path: '/dayforace/time/edit/:id',
            name: 'UpdateSupportStaff',
            component: () => import('../components/Forms/SupportStaffMaster/AddUpdateSupportStaff.vue')
        },
        {
            path: '/dayforace/time/spanish/list',
            name: 'ListSpanishSupportStaff',
            component: () => import('../components/Forms/SupportStaffMaster/ListSpanishSupportStaff.vue')
        },
        {
            path: '/dayforace/time/spanish/add',
            name: 'AddUpdateSpanishSupportStaff',
            component: () => import('../components/Forms/SupportStaffMaster/AddUpdateSpanishSupportStaff.vue')
        },
        {
            path: '/dayforace/time/spanish/edit/:id',
            name: 'UpdateSpanishSupportStaff',
            component: () => import('../components/Forms/SupportStaffMaster/AddUpdateSpanishSupportStaff.vue')
        },
        {
            path: '/technician/uniform/list',
            name: 'ListTechnicianUniform',
            component: () => import('../components/Forms/TechnicianUniformMaster/ListTechnicianUniform.vue')
        },
        {
            path: '/technician/uniform/add',
            name: 'AddUpdateTechnicianUniform',
            component: () => import('../components/Forms/TechnicianUniformMaster/AddUpdateTechnicianUniform.vue')
        },
        {
            path: '/office/supplier/list',
            name: 'ListOfficeSupplier',
            component: () => import('../components/Forms/OfficeSupplier/ListOfficeSupplier.vue')
        },
        {
            path: '/office/supplier/add',
            name: 'AddUpdateOfficeSupplier',
            component: () => import('../components/Forms/OfficeSupplier/AddUpdateOfficeSupplier.vue')
        },
        {
            path: '/office/supplier/edit/:id',
            name: 'UpdateOfficeSupplier',
            component: () => import('../components/Forms/OfficeSupplier/AddUpdateOfficeSupplier.vue')
        },
        {
            path: '/vendor/item/list',
            name: 'ListVendor',
            component: () => import('../components/Forms/VendorMaster/ListVendor.vue')
        },
        {
            path: '/vendor/item/add',
            name: 'AddUpdateVendor',
            component: () => import('../components/Forms/VendorMaster/AddUpdateVendor.vue')
        },
        {
            path: '/vendor/item/edit/:id',
            name: 'UpdateVendor',
            component: () => import('../components/Forms/VendorMaster/AddUpdateVendor.vue')
        },
        {
            path: '/form/email/manager/list',
            name: 'ListFormEmailManager',
            component: () => import('../components/Forms/FormEmailManager/ListFormEmailManager.vue')
        },
        {
            path: '/form/email/manager/add',
            name: 'AddUpdateFormEmailManager',
            component: () => import('../components/Forms/FormEmailManager/AddUpdateFormEmailManager.vue')
        },
        {
            path: '/form/email/manager/edit/:id',
            name: 'UpdateFormEmailManager',
            component: () => import('../components/Forms/FormEmailManager/AddUpdateFormEmailManager.vue')
        },
        {
            path: '/labor/review/list',
            name: 'ListLaborReview',
            component: () => import('../components/Forms/LaborReview/ListLaborReview.vue')
        },
        {
            path: '/labor/review/add',
            name: 'AddUpdateLaborReview',
            component: () => import('../components/Forms/LaborReview/AddUpdateLaborReview.vue')
        },
        {
            path: '/labor/review/edit/:id',
            name: 'UpdateLaborReview',
            component: () => import('../components/Forms/LaborReview/AddUpdateLaborReview.vue')
        },
        {
            path: '/ppe/order/supplies/list',
            name: 'ListPPEOrderSupplies',
            component: () => import('../components/Forms/PPEOrderSupplies/ListPPEOrderSupplies.vue')
        },
        {
            path: '/ppe/order/supplies/add',
            name: 'AddUpdatePPEOrderSupplies',
            component: () => import('../components/Forms/PPEOrderSupplies/AddUpdatePPEOrderSupplies.vue')
        },
        {
            path: '/ppe/order/supplies/edit/:id',
            name: 'UpdatePPEOrderSupplies',
            component: () => import('../components/Forms/PPEOrderSupplies/AddUpdatePPEOrderSupplies.vue')
        },
        {
            path: '/training/travel/list',
            name: 'ListTrainingTravel',
            component: () => import('../components/Forms/TrainingTravelMaster/ListTrainingTravel.vue')
        },
        {
            path: '/EXWU/tablet/list',
            name: 'ListTableRequest',
            component: () => import('../components/Forms/EXWU/ListTableRequest.vue')
        },
        {
            path: '/EXWU/tablet/add',
            name: 'AddUpdateTableReq',
            component: () => import('../components/Forms/EXWU/AddUpdateTabletReq.vue')
        },
        {
            path: '/EXWU/tablet/edit/:id',
            name: 'UpdateTableReq',
            component: () => import('../components/Forms/EXWU/AddUpdateTabletReq.vue')
        },
        {
            path: '/training/travel/add',
            name: 'AddUpdateTrainingTravel',
            component: () => import('../components/Forms/TrainingTravelMaster/AddUpdateTrainingTravel.vue')
        },
        {
            path: '/training/travel/edit/:id',
            name: 'UpdateTrainingTravel',
            component: () => import('../components/Forms/TrainingTravelMaster/AddUpdateTrainingTravel.vue')
        },
        {
            path: '/cpc/list',
            name: 'ListCPC',
            component: () => import('../components/Forms/CPC/ListCPC.vue')
        },
        {
            path: '/cpc/add',
            name: 'AddUpdateCPC',
            component: () => import('../components/Forms/CPC/AddUpdateCPC.vue')
        },
        {
            path: '/cpc/edit/:id',
            name: 'UpdateCPC',
            component: () => import('../components/Forms/CPC/AddUpdateCPC.vue')
        },
        {
            path: '/key/list',
            name: 'ListCalendarKey',
            component: () => import('../components/ListCalendarKey.vue')
        },
        {
            path: '/key/add',
            name: 'AddCalendarKey',
            component: () => import('../components/AddCalendarKey.vue')
        },
        {
            path: '/key/edit/:id',
            name: 'EditCalendarKey',
            component: () => import('../components/AddCalendarKey.vue')
        },
        {
            path: '/hrdragview',
            name: 'HRDragView',
            component: () => import('../components/backend/user/HRDragView.vue')
        },
        {
            path: '/attachment-group/list',
            name: 'ListAttachmentGroup',
            component: () => import('../components/ListAttachmentGroup.vue')
        },
        {
            path: '/:type/add_attachment',
            name: 'AttachmentAddForm',
            component: () => import('../components/AttachmentAddForm.vue')
        },
        {
            path: '/:type/edit_attachment/:id',
            name: 'AttachmentEditForm',
            component: () => import('../components/AttachmentAddForm.vue')
        },
        {
            path: '/:type',
            name: 'CommonPage',
            component: () => import('../components/CommonPage.vue'),
            props : true
        },
        {
            path: '/:type/:group_id',
            name: 'ClickAttachmentGroup',
            component: () => import('../components/CommonPage.vue')
        },
        {
            path: '/:type/test',
            name: 'CommonPageOld',
            component: () => import('../components/CommonPageOld.vue')
        },
        {
            path: '/:type/view',
            name: 'CommonPostDetail',
            component: () => import('../components/CommonPostDetail.vue')
        },
        {
            path: '/:type/add_post',
            name: 'DiscussionAddForm',
            component: () => import('../components/DiscussionAddForm.vue')
        },
        {
            path: '/:type/edit_post/:id',
            name: 'DiscussionEditForm',
            component: () => import('../components/DiscussionAddForm.vue')
        },
        {
            path: '/:type/add_date',
            name: 'EventAddForm',
            // component: EventAddForm
            component: () => import('../components/EventAddForm.vue')
        },
        {
            path: '/:type/edit_date/:id',
            name: 'EventEditForm',
            component: () => import('../components/EventAddForm.vue')
        },
        /*{
            path: '/user/list',
            name: 'UserList',
            // component: UserList
            component: () => import('../components/UserList.vue')
        },*/
        /* {
            path: '/attachment-group/list',
            name: 'ListAttachmentGroup',
            // component: ListAttachmentGroup
            component: () => import('../components/ListAttachmentGroup.vue')
        }, */
        {
            path: '/attachment-group/add/:id',
            name: 'AddAttachmentGroup',
            component: () => import('../components/AddAttachmentGroup.vue')
        },
        {
            path: '/attachment-group/edit/:id',
            name: 'EditAttachmentGroup',
            component: () => import('../components/AddAttachmentGroup.vue')
        },
        {
            path: '/globalsearch/:searchText',
            name: 'GlobalSearchView',
            component: () => import('../components/GlobalSearchView.vue'),
            props: true
        },
        {
            path: '/:type/add_image',
            name: 'ImageAddForm',
            component: () => import('../components/ImageAddForm.vue')
        },
        {
            path: '/:type/edit_image/:id',
            name: 'ImageEditForm',
            component: () => import('../components/ImageAddForm.vue')
        },
        {
            path: '/dispute/chargeback/list',
            name: 'ListDisputeChargebackRequest',
            component: () => import('../components/Forms/DisputeChargebackMaster/ListDisputeChargebackRequest.vue')
        },
        {
            path: '/directory/extensions/:slug',
            name: 'ViewLocationDepartments',
            component: () => import('../components/ContactExtension/ViewLocationDepartments.vue')
        },
        {
            path: '/directory/extensionslist/:company/:location',
            name: 'ViewContactList',
            component: () => import('../components/ContactExtension/ViewContactList.vue')
        },
        {
            path: '/extension/company/list',
            name: 'ListCExtCompany',
            component: () => import('../components/ContactExtension/CompanyMaster/ListCExtCompany.vue')
        },
        {
            path: '/extension/company/add',
            name: 'AddUpdateCExtCompany',
            component: () => import('../components/ContactExtension/CompanyMaster/AddUpdateCExtCompany.vue')
        },
        {
            path: '/extension/company/edit/:id',
            name: 'UpdateCExtCompany',
            component: () => import('../components/ContactExtension/CompanyMaster/AddUpdateCExtCompany.vue')
        },
        {
            path: '/extension/location/list',
            name: 'ListCExtLocation',
            component: () => import('../components/ContactExtension/LocationMaster/ListCExtLocation.vue')
        },
        {
            path: '/extension/location/add',
            name: 'AddUpdateCExtLocation',
            component: () => import('../components/ContactExtension/LocationMaster/AddUpdateCExtLocation.vue')
        },
        {
            path: '/extension/location/edit/:id',
            name: 'UpdateCExtLocation',
            component: () => import('../components/ContactExtension/LocationMaster/AddUpdateCExtLocation.vue')
        },
        {
            path: '/extension/location/department/list',
            name: 'ListCExtDepartment',
            component: () => import('../components/ContactExtension/DepartmentMaster/ListCExtDepartment.vue')
        },
        {
            path: '/extension/location/department/add',
            name: 'AddUpdateCExtDepartment',
            component: () => import('../components/ContactExtension/DepartmentMaster/AddUpdateCExtDepartment.vue')
        },
        {
            path: '/extension/location/department/edit/:id',
            name: 'UpdateCExtDepartment',
            component: () => import('../components/ContactExtension/DepartmentMaster/AddUpdateCExtDepartment.vue')
        },
        {
            path: '/extension/location/contact/list',
            name: 'ListCExtLocationContact',
            component: () => import('../components/ContactExtension/LocationContactMaster/ListCExtLocationContact.vue')
        },
        {
            path: '/extension/location/contact/add',
            name: 'AddUpdateCExtLocationContact',
            component: () => import('../components/ContactExtension/LocationContactMaster/AddUpdateCExtLocationContact.vue')
        },
        {
            path: '/extension/location/contact/edit/:id',
            name: 'UpdateCExtLocationContact',
            component: () => import('../components/ContactExtension/LocationContactMaster/AddUpdateCExtLocationContact.vue')
        },
        {
            path: '/extension/department/contact/list',
            name: 'ListCExtDepartmentContact',
            component: () => import('../components/ContactExtension/DepartmentContactMaster/ListCExtDepartmentContact.vue')
        },
        {
            path: '/extension/department/contact/add',
            name: 'AddUpdateCExtDepartmentContact',
            component: () => import('../components/ContactExtension/DepartmentContactMaster/AddUpdateCExtDepartmentContact.vue')
        },
        {
            path: '/extension/department/contact/edit/:id',
            name: 'UpdateCExtDepartmentContact',
            component: () => import('../components/ContactExtension/DepartmentContactMaster/AddUpdateCExtDepartmentContact.vue')
        },
        {
            path: '/extension/user/contact/list',
            name: 'ListCExtUserContact',
            component: () => import('../components/ContactExtension/UserContactMaster/ListCExtUserContact.vue')
        },
        {
            path: '/extension/user/contact/add',
            name: 'AddUpdateCExtUserContact',
            component: () => import('../components/ContactExtension/UserContactMaster/AddUpdateCExtUserContact.vue')
        },
        {
            path: '/extension/user/contact/edit/:id',
            name: 'UpdateCExtUserContact',
            component: () => import('../components/ContactExtension/UserContactMaster/AddUpdateCExtUserContact.vue')
        },
        /* {
            path: '/abandoned/vehicle/list',
            name: 'ListAbandonedVehicleRequest',
            // component: ListAbandonedVehicleRequest,
            component: () => import('../components/Forms/AbandonedVehicleMaster/ListAbandonedVehicleRequest.vue')
        }, */
        {
            path: '/service/tech/support/list',
            name: 'ListServiceTechSupportRequest',
            component: () => import('../components/Forms/ServiceTechSupportMaster/ListServiceTechSupportRequest.vue')
        },
    ]
});
